<template>
  <div class="container" ref="wrapper">
    <van-sticky>
      <van-nav-bar title="星光计划" left-arrow @click-left="$router.back()" >
      </van-nav-bar>
    </van-sticky>
    <van-search
      v-model="page.filterData.title"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-cell-group>
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
          <van-cell
            v-for="item in articles"
            :key="item.id.toString()"
            @click="toDetail(item)"
          >
            <div
              style="display: table-cell; vertical-align: middle"
              class="zntitle van-multi-ellipsis--l3"
            >
              <h3 style="display: inline-block">{{ item.title }}</h3>
            </div>
 
            <div style="float: right">{{ item.startTime }}至{{ item.endTime }} </div>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {getQuestionnaireList2} from "@/api/questionnaire"
export default {
  name: "question",
  data() {
    return {
      scroll: 0,
      refreshing: false,
      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          title: "",
        },
      },
    };

  },

activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    async onLoad() {
      this.upLoading = true;
      let aresult = await getQuestionnaireList2({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },

     toDetail(item) {

      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
        //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      this.$router.push({
        path: "/stardetail",
        query: { id: item.id},
      });
    },
  }



};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
</style>
